'use strict';

module.exports = {
    fbInitialize: $(document).ready(function () {
        $.ajaxSetup({ cache: true });
        $.getScript('https://connect.facebook.net/en_US/sdk.js', function () {
            // eslint-disable-next-line no-undef
            FB.init({
                appId: '{220365796071758}',
                version: 'v2.7' // or v2.1, v2.2, v2.3, ...
            });
        });
    }),

    toggleMenu: function () {
        $('body').on('click', '.header__mobile, .header__desktop', function (ev) {
            if ($(window).width() <= 801) {
                if (!$('.header__mobile').hasClass('mobile-active') && $(ev.target).hasClass('minicart-total')) {
                    $('.header__mobile').css({ overflow: 'visible' }).addClass('mobile-active');
                    $('.minicart-wrapper').addClass('active');
                    $('body').addClass('nav-open');
                    $('.ticket__card').css({ 'z-index': '0' });
                    $('html, body').css({ width: '100%', height: '100%', overflow: 'hidden' });


                    $('.ticket__tripsConnection--desktop').css('z-index', '0');
                } else if ($(ev.target).prop('classList').value == 'header__mobile mobile-active' || $(ev.target).hasClass('close-icon')) {
                    $('body').removeClass('nav-open');
                    $('.minicart-wrapper').removeClass('active');
                    $('.header__mobile').removeClass('mobile-active');

                    $('.ticket__tripsConnection--desktop').css('z-index', '3');
                    $('.slick-arrow').css({ 'z-index': '0' });
                    $('.ticket__card').css({ 'z-index': '0' });

                    $('.ticket__card').css({ 'z-index': 'auto' });
                    $('html, body').css({ width: '100%', height: '100%', overflow: 'auto' });

                    setTimeout(function () {
                        $('.header__mobile').css({ overflow: 'hidden' });
                    }, 320);
                }
            } else if ($(ev.target).hasClass('minicart-total')) {
                $('.header__desktop').addClass('desktop-active');
                $(this).closest('.header__desktop').find('.minicart-wrapper').addClass('active');

                $('html, body').css({ width: '100%', height: '100%', overflow: 'hidden' });

                $('.ticket__card, .slick-arrow, .ticket__tripsConnection--desktop, .ticket__tag__wrap').each(function () {
                    $(this).css({ 'z-index': '0' });
                });
                $('body').trigger('amplitude:minicartView');

            } else if ($(ev.target).hasClass('close-icon')) {
                $('.minicart-wrapper').removeClass('active');
                $('.header__desktop').removeClass('desktop-active');

                $('.ticket__tripsConnection--desktop').css('z-index', '3');
                $('html, body').css({ width: '100%', height: '100%', overflow: 'auto' });
                $('.ticket__card').css({ 'z-index': 'auto' });
            }
        });
        // ajusta tamanho da tela conforme viweport
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    },

    showMinicart: function () {
        if ($('#minicartShow').length && $('#minicartShow').val() == 'true') {
            $('.minicart-total').click();
        }
    },

    removeProductModal: function () {
        $('body').on('mouseup', '#removeProductModal .modal-dialog .modal-footer button, .delete-confirmation-header .close', function (ev) {
            ev.preventDefault();
            if ($(ev.target).prop('classList').value == 'btn btn-primary cart-delete-confirmation-btn'
                || $(ev.target).parent().prop('classList').value === 'close') {
                var classModal = $(this).parent().parent().find('#removeProductModal');
                classModal.addClass('show');

                classModal.css({
                    display: 'block',
                    'z-index': '100',
                    'background-color': 'rgba(0,0,0,0.5)',
                    transition: 'opacity 300ms ease-in-out'
                });
                $('html, body').css({ width: '100%', height: '100%', overflow: 'auto' });
                $('.header__mobile').removeClass('mobile-active');

                let lastUpsellInteraction = $('body').find('input[name="upsellChecker"]').val();

                if ($(window).width() >= 801) {
                    setTimeout(function () {
                        if (lastUpsellInteraction.length > 0) {
                            $('body').find('.ticket__button[data-url="' + lastUpsellInteraction + '"]').trigger('click');
                        } else {
                            $('.ticket__card.selected').find('.ticket__button').trigger('click');
                        }
                    }, 50);
                }

                $(classModal).find('.cart-delete-confirmation-btn').on('click', function () {
                    var modal = $(this).closest('#removeProductModal');
                    modal.removeClass('show');
                    modal.css({
                        display: 'none'
                    });

                    $('html, body').css({ width: '100%', height: '100%', overflow: 'auto' });
                    if ($(window).width() >= 801) {
                        if (lastUpsellInteraction.length > 0) {
                            $('body').find('.ticket__button[data-url="' + lastUpsellInteraction + '"]').trigger('click');
                        } else {
                            $('.ticket__card.selected').find('.ticket__button').trigger('click');
                        }
                    }
                });
            }

            if ($(ev.target).prop('classList').value === 'btn btn-outline-primary') {
                ev.preventDefault();
                var modal = $(this).closest('#removeProductModal');

                modal.removeClass('show');

                modal.css({
                    display: 'none'
                });

                $('html, body').css({ width: '100%', height: '100%', overflow: 'auto' });
                $('.header__mobile').removeClass('mobile-active');
            }
        });

        $('body').on('mouseup', '.remove-line-item .remove-product', function (ev) {
            if ($(ev.target).prop('classList').value === 'remove-btn remove-product btn btn-light') {
                var classModal = $(this).closest('.minicart-container').find('#removeProductModal');
                classModal.addClass('show');

                classModal.css({
                    display: 'block',
                    'z-index': '100',
                    'background-color': 'rgba(0,0,0,0.5)',
                    transition: 'opacity 300ms ease-in-out'
                });

                $('.ticket__card').css({ 'z-index': '0' });

                $(classModal).find('.close').on('click', function (event) {
                    event.preventDefault();
                    var modal = $(this).closest('#removeProductModal');

                    modal.removeClass('show');

                    modal.css({
                        display: 'none'
                    });
                    $('html, body').css({ width: '100%', height: '100%', overflow: 'auto' });
                    $('.header__mobile').removeClass('mobile-active');
                });
            }
        });
    },

    removeAllProductModal: function () {
        $('body').on('mouseup', '.minicart-footer-button-clear', function (ev) {
            if ($(ev.target).prop('classList').value === 'minicart-footer-button-clear remove-product') {
                var classModal = $(this).closest('.minicart-container').find('#removeAllProductModal');
                classModal.addClass('show');

                classModal.css({
                    display: 'block',
                    'z-index': '100',
                    'background-color': 'rgba(0,0,0,0.5)',
                    transition: 'opacity 300ms ease-in-out'
                });

                $('.ticket__card').css({ 'z-index': '0' });

                $(classModal).find('.close').on('click', function (event) {
                    event.preventDefault();
                    var modal = $(this).closest('#removeAllProductModal');

                    modal.removeClass('show');

                    modal.css({
                        display: 'none'
                    });

                    $('html, body').css({ width: '100%', height: '100%', overflow: 'auto' });
                });

                $(classModal).find('.cart-delete-confirmation-btn').on('click', function () {
                    var modal = $(this).closest('#removeAllProductModal');
                    modal.removeClass('show');
                    modal.css({
                        display: 'none'
                    });
                    let lastUpsellInteraction = $('body').find('input[name="upsellChecker"]').val();

                    $('html, body').css({ width: '100%', height: '100%', overflow: 'auto' });
                    if ($(window).width() >= 801) {
                        if (lastUpsellInteraction.length > 0) {
                            $('body').find('.ticket__button[data-url="' + lastUpsellInteraction + '"]').trigger('click');
                        } else {
                            $('.ticket__card.selected').find('.ticket__button').trigger('click');
                        }
                    }
                });
            }
        });
        $('body').on('mouseup', '#removeAllProductModal .modal-dialog .modal-footer button, .delete-confirmation-header .close', function (ev) {
            ev.preventDefault();
            if ($(ev.target).prop('classList').value == 'btn btn-primary cart-delete-confirmation-btn'
                || $(ev.target).parent().prop('classList').value === 'close') {
                var classModal = $(this).parent().parent().find('#removeAllProductModal');
                classModal.addClass('show');

                classModal.css({
                    display: 'block',
                    'z-index': '100',
                    'background-color': 'rgba(0,0,0,0.5)',
                    transition: 'opacity 300ms ease-in-out'
                });
                $('html, body').css({ width: '100%', height: '100%', overflow: 'auto' });
                $('.header__mobile').removeClass('mobile-active');
            }

            if ($(ev.target).prop('classList').value === 'btn btn-outline-primary') {
                ev.preventDefault();
                var modal = $(this).closest('#removeAllProductModal');

                modal.removeClass('show');

                modal.css({
                    display: 'none'
                });

                $('html, body').css({ width: '100%', height: '100%', overflow: 'auto' });
                $('.header__mobile').removeClass('mobile-active');
            }
        });
    },
    loginModal: function () {
        $('.minicart-footer-button').on('click', function (ev) {
            $('.searchTicketsLoading__subTitle').addClass('d-none');
            $('body').trigger('busLoading:init');
            var url = $(this).attr('href');
            window.location.href = url;
        });
    }
};
